import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import './PostList.scss';

export default class IndexPage extends React.Component {
  render() {
    const { posts, title } = this.props

    return (
      <section>
        <div className="container is-fluid">
          <div className="content">
            <h1 className="has-text-weight-bold is-size-2">{title}</h1>
          </div>
          {posts.map(({ node: post }) => (
            <div
              className="post-wrapper content"
              key={post.id}
            >
              <h2 className="has-text-weight-bold is-size-3">
                <Link
                  to={post.slug}
                  dangerouslySetInnerHTML={{
                    __html: post.title
                  }}
                >
                </Link>
              </h2>
              <p>
                <strong>{post.date}</strong>
              </p>
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: post.excerpt.replace(/<p class="link-more.*/, ''),
                  }}
                />
                <Link className="button is-black is-small" to={post.slug}>
                  Read More
                </Link>
              </div>
            </div>
          ))}
        </div>
      </section>
    )
  }
}

IndexPage.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export const pageQuery = graphql`
  fragment PostListFields on wordpress__POST {
    id
    title
    excerpt
    date(formatString: "MMMM DD, YYYY")
    slug
  }
`
